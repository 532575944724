import {Route, Routes} from 'react-router-dom'
import {TermsConditionsPage} from './pages/TermsConditionsPage'
import {LandingPage} from './pages/LandingPage'
import {OssPage} from './pages/OssPage'
import {PrivacyPage} from './pages/PrivacyPage'

export const Router = () => {
  return (
    <Routes>
      <Route path="/" element={<LandingPage />} />
      <Route path="ossattribution" element={<OssPage />} />
      <Route path="privacy" element={<PrivacyPage />} />
      <Route path="termsconditions" element={<TermsConditionsPage />} />
    </Routes>
  )
}
