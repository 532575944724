import {Divider, Link as MuiLink, Stack, styled, Typography} from '@mui/material'
import {useTranslation} from 'react-i18next'
import {Link as ReactRouterLink} from 'react-router-dom'

const FooterTypography = styled(Typography)({
  padding: 8,
  noWrap: true,
  variant: 'subtitle2'
})

export const Footer = () => {
  const {t} = useTranslation()
  const styles = {
    appFooter: {
      textAlign: 'center',
      justifyContent: 'center'
    }
  }

  return (
    <Stack
      direction={{xs: 'column', sm: 'row'}}
      divider={<Divider orientation="vertical" flexItem />}
      sx={styles.appFooter}
      data-test-id="app-footer"
    >
      <FooterTypography>
        <MuiLink
          to="/ossattribution"
          color="text.disabled"
          data-test-id="oss"
          component={ReactRouterLink}
        >
          {t('Footer.Open_oss_licenses')}
        </MuiLink>
      </FooterTypography>
      <FooterTypography>
        <MuiLink
          to="/privacy"
          color="text.disabled"
          data-test-id="privacy"
          component={ReactRouterLink}
        >
          {t('Footer.PrivacyNotice')}
        </MuiLink>
      </FooterTypography>
      <FooterTypography>
        <MuiLink
          to="/termsconditions"
          color="text.disabled"
          data-test-id="termsconditions"
          component={ReactRouterLink}
        >
          {t('Footer.TermConditions')}
        </MuiLink>
      </FooterTypography>
      <FooterTypography>
        <Typography
          variant="body2"
          sx={{justifyContent: 'center', alignItems: 'center', color: 'text.disabled'}}
        >
          {Intl.DateTimeFormat().resolvedOptions().locale} / {window.navigator.language} /{' '}
          {window.navigator.languages.join(', ')}
        </Typography>
      </FooterTypography>
    </Stack>
  )
}
