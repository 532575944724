export type SearchProps = {
  searchType: SearchTypeAction
  searchValue: string
  setSearchValue: React.Dispatch<React.SetStateAction<string>>
  searchSubmit: React.Dispatch<React.SetStateAction<boolean>>
  setHeadline: React.Dispatch<React.SetStateAction<string>>
}

export enum SearchTypeAction {
  VehicleSearch = 'vehicles',
  DriverSearch = 'drivers',
  DispatchSearch = 'dispatch'
}
