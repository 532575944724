import {Box, Fade, Menu, MenuItem} from '@mui/material'
import React from 'react'
import {getLanguageTitle} from '../../common/language'

import {Flag} from './Flag'

interface Props {
  availableLanguages: string[]
  selectLanguage: (languageCode: string) => void
  currentLanguage: string
}

export const LanguagePicker = ({availableLanguages, selectLanguage, currentLanguage}: Props) => {
  const styles = {
    select: {
      cursor: 'pointer'
    },
    title: {
      marginLeft: '10px'
    },
    menuItem: {
      '&.Mui-focusVisible': {
        backgroundColor: 'transparent'
      }
    },
    menuItemBox: {
      display: 'flex',
      alignItems: 'center'
    }
  }

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)
  const open = Boolean(anchorEl)

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleSelect = (language: string) => {
    handleClose()
    selectLanguage(language)
  }

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  return (
    <Box>
      <Box
        display="flex"
        alignItems="center"
        onClick={handleClick}
        sx={styles.select}
        data-test-id="languagePicker-select"
      >
        <Flag country={currentLanguage} />
        <Box component="span" sx={styles.title} data-test-id="languagePicker-selectedItem">
          {getLanguageTitle(currentLanguage)}
        </Box>
      </Box>
      <Menu
        data-test-id="languagePicker-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        {availableLanguages.map((language, index) => (
          <MenuItem
            value={language}
            key={index}
            onClick={() => handleSelect(language)}
            data-test-id={`languagePicker-menuItem-${index}`}
            sx={styles.menuItem}
          >
            <Box sx={styles.menuItemBox}>
              <Flag country={language} />
              <Box component="span" sx={styles.title}>
                {getLanguageTitle(language)}
              </Box>
            </Box>
          </MenuItem>
        ))}
      </Menu>
    </Box>
  )
}
