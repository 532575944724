import {Button, Grid, MenuItem, Select, SelectChangeEvent} from '@mui/material'
import {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {dispatchSearchCountries} from '../../common/countries'

export const DispatchSearch = (props: {
  searchSubmit: React.Dispatch<React.SetStateAction<boolean>>
  setSearchValue: React.Dispatch<React.SetStateAction<string>>
  setHeadline: React.Dispatch<React.SetStateAction<string>>
}) => {
  const {t} = useTranslation()
  const [countryCode, setCountryCode] = useState<string>()

  const handleChange = (event: SelectChangeEvent) => {
    const countryCode = event.target.value
    setCountryCode(countryCode)
    const dispatchSearchCountryCode = dispatchSearchCountries.find(
      (x) => x.countryCode === countryCode
    )?.dispatchGroupCountryCode
    props.setSearchValue(dispatchSearchCountryCode ?? '')
    props.searchSubmit(false)
  }

  const onSubmit = () => {
    props.searchSubmit(true)
    props.setHeadline(
      `${t('LandingPage.DispatchHeadline')} ${t(`LandingPage.Search.Countries.${countryCode}`)}`
    )
  }

  return (
    <>
      <Grid item xs={12} justifyContent="center">
        <Select
          sx={{minWidth: 150, maxHeight: 40, mr: 2}}
          value={countryCode}
          onChange={handleChange}
          data-test-id="dispatch-search-select"
        >
          {dispatchSearchCountries.map((dispatchSearchCountry) => (
            <MenuItem
              key={`dispatch-search-select-${dispatchSearchCountry.countryCode}`}
              value={dispatchSearchCountry.countryCode}
              data-test-id={`dispatch-search-select-${dispatchSearchCountry.countryCode}`}
            >
              {t(`LandingPage.Search.Countries.${dispatchSearchCountry.countryCode}`)}
            </MenuItem>
          ))}
        </Select>
        <Button
          sx={{my: 2}}
          variant="contained"
          color="primary"
          onClick={onSubmit}
          data-test-id="dispatch-search-submit"
        >
          {t('LandingPage.Search.Submit')}
        </Button>
      </Grid>
    </>
  )
}
