import GB from './svg/GB.svg'
import NL from './svg/NL.svg'
import FR from './svg/FR.svg'
import DK from './svg/DK.svg'
import SV from './svg/SV.svg'
import BE from './svg/BE.svg'
import NO from './svg/NO.svg'

export const svgFlagSources: {[index: string]: string} = {
  EN: GB,
  NL,
  FR,
  DK,
  SV,
  'FR-BE': BE,
  NO
}
