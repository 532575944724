import {Grid, Typography} from '@mui/material'
import {useTranslation} from 'react-i18next'
interface Props {
  searchId: string
}
export const RosterNotFound = ({searchId}: Props) => {
  const {t} = useTranslation()
  return (
    <Grid item xs={12}>
      <Typography>
        {t('LandingPage.Search.NotFoundInformation', {
          searchId: searchId
        })}
      </Typography>
      <Typography> {t('LandingPage.Search.IdMistypeInformation')}</Typography>
      <Typography pt={2} sx={{fontWeight: 'bold'}}>
        {t('LandingPage.Search.TryAgain')}
      </Typography>
    </Grid>
  )
}
