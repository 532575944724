import {I18nextProvider} from 'react-i18next'
import i18next from './../i18n'
import {TermsConditions} from '../components/TermsConditions'

export const TermsConditionsPage = () => {
  const termsConditionsI18nextInstance = i18next.cloneInstance()

  return (
    <I18nextProvider i18n={termsConditionsI18nextInstance}>
      <TermsConditions />
    </I18nextProvider>
  )
}
