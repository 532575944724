import {List, ListItem, ListItemText, Typography} from '@mui/material'
import {useDispatchGroups} from '../../hooks/useRoster'
import {useTranslation} from 'react-i18next'

export const DispatchGroupOverview = (props: {searchValue: string}) => {
  const {t} = useTranslation()
  const dispatchGroups = useDispatchGroups(props.searchValue)

  return (
    <List>
      <Typography variant="h6" fontWeight={'bold'} data-test-id="dispatchInfo">
        {t('LandingPage.Search.DispatchResult')}
      </Typography>
      {dispatchGroups.data?.dispatchGroups.map((item) => (
        <>
          <ListItem>
            <ListItemText primary={item.dispatchGroupValue} />
          </ListItem>
        </>
      ))}
    </List>
  )
}
