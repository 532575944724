/* eslint-disable complexity */

export const getLanguageTitle = (languageCode: string): string | null => {
  switch (languageCode) {
    case 'nl':
      return 'Nederland'
    case 'fr':
      return 'France'
    case 'fr-BE':
      return 'Belgique'
    case 'dk':
      return 'Danmark'
    case 'sv':
      return 'Sverige'
    case 'en':
      return 'England'
    case 'no':
      return 'Norge'
    default:
      return ''
  }
}
