import React, {PropsWithChildren, Suspense, useContext} from 'react'
import axios, {AxiosRequestConfig, AxiosInstance} from 'axios'
import {QueryClient, QueryClientProvider} from 'react-query'
import {CircularProgress} from '@mui/material'
import {BrowserRouter} from 'react-router-dom'

const axiosConfig: AxiosRequestConfig = {
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  baseURL: `https://${(window as any).env.BACKEND_ORIGIN}`,
  headers: {
    'Content-Type': 'application/json'
  }
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
})
const axiosInstance: AxiosInstance = axios.create(axiosConfig)

export const axiosContext = React.createContext<AxiosInstance>(axiosInstance)

export function useApi(): AxiosInstance {
  return useContext(axiosContext)
}

const OnTimeProvider: React.FC<PropsWithChildren<unknown>> = ({children}) => {
  return (
    <Suspense fallback={<CircularProgress data-test-id="init-fallback" />}>
      <axiosContext.Provider value={axiosInstance}>
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>{children}</BrowserRouter>
        </QueryClientProvider>
      </axiosContext.Provider>
    </Suspense>
  )
}

export default OnTimeProvider
