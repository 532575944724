import {OssLicense} from '../components/OpenSourceLicense'
import licenseOutput from '../licenses.json'

interface PackageReference {
  copyright: string
  licenses: string
  repository: string
  licenseText: string
}

export const getLicenses = (): Array<OssLicense> => {
  return Object.entries(licenseOutput).map(([moduleName, moduleProp]) => {
    const moduleDetail: PackageReference = moduleProp as PackageReference
    const regex = /^(.*)@(.*)$/gm
    const result = regex.exec(moduleName)
    const {copyright, licenses, repository, licenseText} = moduleDetail
    return {
      moduleName,
      name: result && result[1],
      version: result && result[2],
      spdx: licenses,
      licenseText,
      copyright,
      repository
    }
  })
}
