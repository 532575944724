import {Box} from '@mui/material/'
import React from 'react'

import {svgFlagSources} from '../../assets/flags'
import {FallbackCountryCode} from '../../i18n'

type Props = {
  country: string
  onClick?: (event: React.MouseEvent<HTMLElement>) => void
}

export const Flag: React.FC<Props> = ({country, onClick}) => {
  const styles = {
    container: {
      display: 'inline-flex'
    }
  }
  let svgFlagSource = svgFlagSources[country.toUpperCase()]

  if (!svgFlagSource) {
    svgFlagSource = svgFlagSources[FallbackCountryCode]
  }
  return (
    <Box sx={styles.container} data-test-id={`Flag-${country}`}>
      <img src={svgFlagSource} onClick={onClick} alt={country} />
    </Box>
  )
}
