import {Box, Container} from '@mui/material'
import {useTranslation} from 'react-i18next'
import {LanguagePicker} from './languagePicker/LanguagePicker'
import {languageCodes} from '../i18n'

export const TermsConditions = () => {
  const styles = {
    languagePickerBox: {
      display: 'flex',
      marginTop: '20px',
      justifyContent: 'flex-end'
    }
  }

  const {t, i18n} = useTranslation()

  const handleLanguageSelect = (languageCode: string) => {
    void i18n.changeLanguage(languageCode)
  }

  return (
    <Container data-test-id="termsConditionsPage-container">
      <Box sx={styles.languagePickerBox}>
        <LanguagePicker
          availableLanguages={languageCodes}
          currentLanguage={i18n.resolvedLanguage}
          selectLanguage={handleLanguageSelect}
        />
      </Box>
      <Box
        dangerouslySetInnerHTML={{__html: t('TermsAndConditions')}}
        data-test-id="termsConditionsPage-text"
      />
    </Container>
  )
}
