import {Grid} from '@mui/material'
import {useTranslation} from 'react-i18next'

export const RosterNotAvailable = () => {
  const {t} = useTranslation()
  return (
    <Grid item xs={12}>
      <h3>{t('LandingPage.InformationNotAvailable')} </h3>
    </Grid>
  )
}
