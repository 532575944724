import {Box, Paper, Typography} from '@mui/material'
import {OpenSourceLicense} from '../components/OpenSourceLicense'
import {useTranslation} from 'react-i18next'
import {getLicenses} from '../common/licenses'

export const OssPage = () => {
  const licenses = getLicenses()
  const {t} = useTranslation()

  return (
    <Paper component="main" data-test-id="oss-page" elevation={0}>
      <Typography color="inherit" component="h3" variant="h4" m={2}>
        {t('Footer.Open_oss_licenses')}
      </Typography>
      <Box p={1} data-test-id="licenses-items">
        {licenses.map((license, index) => (
          <OpenSourceLicense key={index} {...license} />
        ))}
      </Box>
    </Paper>
  )
}
