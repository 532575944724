import {AppBar, IconButton, Toolbar, Typography} from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import rosterImg from '../assets/images/Roster-192.svg'
import {Link} from 'react-router-dom'

export const Header = () => {
  const styles = {
    appHeader: {
      IconButton: {
        mr: 2,
        display: {sm: 'none'}
      },
      Typography: {
        flexGrow: 1,
        display: {sm: 'block'},
        margin: 'auto'
      }
    },
    headerLink: {
      display: 'flex',
      textDecoration: 'none',
      color: 'white'
    }
  }

  return (
    <AppBar component="nav" data-test-id="app-header" position="sticky">
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          sx={styles.appHeader.IconButton}
        >
          <MenuIcon />
        </IconButton>
        <Link to="/" style={styles.headerLink}>
          <IconButton color="inherit" aria-label="search">
            <img
              src={rosterImg}
              alt="Search Icon"
              style={{width: '36px', height: '36px'}}
              data-test-id="header-logo"
            />
          </IconButton>
          <Typography variant="h6" component="div" sx={styles.appHeader.Typography}>
            OnTime
          </Typography>
        </Link>
      </Toolbar>
    </AppBar>
  )
}
