import CssBaseline from '@mui/material/CssBaseline'
import {Shell} from './Shell'
import {Router} from './Router'
import OnTimeProvider from './providers/OnTimeProvider'
export const App = () => {
  return (
    <OnTimeProvider>
      <Shell>
        <CssBaseline />
        <Router />
      </Shell>
    </OnTimeProvider>
  )
}
