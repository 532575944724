import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import {initReactI18next} from 'react-i18next'

export const FallbackLng = 'en'
export const FallbackCountryCode = 'EN'

export const languageCodes = ['en', 'nl', 'dk', 'fr', 'fr-BE', 'sv', 'no']

const resources = Object.fromEntries(
  languageCodes.map((code) => [
    code,
    {
      translation: {
        ...require(`./localization/${code}.json`)
      }
    }
  ])
)

const detectionOptions = {
  order: ['navigator'],
  caches: []
}

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: FallbackLng,
    interpolation: {
      escapeValue: false
    },
    detection: detectionOptions
  })

export default i18n
