import styled from '@emotion/styled'
import {Box, CircularProgress, Typography} from '@mui/material'
import {useTranslation} from 'react-i18next'

const LoadingContainer = styled(Box)({
  display: 'flex',
  height: '100vh',
  width: '100vw',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0
})

const LoadingBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center'
})

export const LoadingSpinner = () => {
  const {t} = useTranslation()

  return (
    <LoadingContainer data-test-id="loading-spinner">
      <LoadingBox>
        <CircularProgress />
        <Typography variant="body1"> {t('Common.Loading')}</Typography>
      </LoadingBox>
    </LoadingContainer>
  )
}
