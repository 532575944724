import {Footer} from './components/Footer'
import {Header} from './components/Header'

export const Shell = (props: {children: unknown}) => {
  return (
    <>
      <Header />
      {props.children}
      <Footer />
    </>
  )
}
