import React, {useState} from 'react'
import {Box, Container, Grid, Tab, Tabs} from '@mui/material'
import {HeadlineInfo} from '../components/HeadlineInfo'
import {RosterDetails} from '../components/rosters/RosterDetails'
import {SearchBox} from '../components/SearchBox'
import {useTranslation} from 'react-i18next'
import {DispatchGroupOverview} from '../components/dispatchGroups/DispatchGroupOverview'
import {SearchTypeAction} from '../common/declarations'

export const LandingPage = () => {
  const {t} = useTranslation()

  const [tabIndex, setTabIndex] = useState(0)
  const [searchValue, setSearchValue] = useState('')
  const [submitClicked, setSubmitClicked] = useState(false)
  const [headline, setHeadline] = useState(t('LandingPage.Headline') ?? '')

  const dispatchGroupVisible = tabIndex == 2 && submitClicked

  const handleTabChange = (event: React.ChangeEvent<unknown>, newValue: number) => {
    setTabIndex(newValue)
    setSearchValue('')
    setSubmitClicked(false)
    if (newValue == 2) {
      const dispatchHeadline = t('LandingPage.DispatchHeadline')
      setHeadline(dispatchHeadline)
    } else {
      const defaultHeadline = t('LandingPage.Headline')
      setHeadline(defaultHeadline)
    }
  }

  return (
    <>
      <Container disableGutters sx={{p: 3, flex: 1}} data-test-id="landing-page">
        <Box my={2}>
          <HeadlineInfo headline={headline} />
          <Tabs sx={{pt: 2}} value={tabIndex} onChange={handleTabChange} variant="scrollable">
            <Tab label={t('LandingPage.Search.Vehicle')} data-test-id="search_vehicle_tab" />
            <Tab label={t('LandingPage.Search.Driver')} data-test-id="search_driver_tab" />
            <Tab label={t('LandingPage.Search.Dispatch')} data-test-id="search_dispatch_tab" />
          </Tabs>
          <Grid pt={2} container>
            <Grid item xs={12}>
              <SearchBox
                searchType={Object.values(SearchTypeAction)[tabIndex]}
                searchValue={searchValue}
                setSearchValue={setSearchValue}
                searchSubmit={setSubmitClicked}
                setHeadline={setHeadline}
              />
            </Grid>
            {dispatchGroupVisible ? (
              <DispatchGroupOverview searchValue={searchValue} />
            ) : (
              <Grid item data-test-id="vehicleInfo">
                <RosterDetails
                  searchValue={searchValue}
                  type={Object.values(SearchTypeAction)[tabIndex]}
                  searchSubmitValue={submitClicked}
                  searchSubmit={setSubmitClicked}
                  setTabIndex={setTabIndex}
                />
              </Grid>
            )}
          </Grid>
        </Box>
      </Container>
    </>
  )
}
