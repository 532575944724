export const dispatchSearchCountries: {countryCode: string; dispatchGroupCountryCode: string}[] = [
  {
    countryCode: 'BE',
    dispatchGroupCountryCode: 'BEN'
  },
  {
    countryCode: 'NL',
    dispatchGroupCountryCode: 'BEN'
  },
  {
    countryCode: 'FR',
    dispatchGroupCountryCode: 'FRA'
  }
]
