import React, {useState} from 'react'
import {SearchOutlined} from '@mui/icons-material'
import {Alert, Button, Grid, InputAdornment, TextField, Typography} from '@mui/material'
import {useTranslation} from 'react-i18next'
import {SearchProps, SearchTypeAction} from '../../common/declarations'

type RosterSearchProps = Pick<
  SearchProps,
  'searchType' | 'searchValue' | 'searchSubmit' | 'setSearchValue'
>

export const RosterSearch = (props: RosterSearchProps) => {
  const {t} = useTranslation()
  const [error, setError] = useState('')
  const handleSearch = async () => {
    if (!props.searchValue || props.searchValue === '') {
      const validationError: string = t('LandingPage.Search.Validation')
      setError(validationError)
      return
    }
    props.searchSubmit(true)
    setError('')
  }

  return (
    <>
      <Grid item xs={12}>
        <TextField
          sx={{m: 2}}
          required
          label={
            props.searchType == SearchTypeAction.DriverSearch
              ? t('LandingPage.DriverLabel')
              : t('LandingPage.VehicleLabel')
          }
          size="small"
          data-test-id="search-input"
          value={props.searchValue}
          onChange={(e) => {
            props.searchSubmit(false)
            props.setSearchValue(e.target.value.trim())
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchOutlined />
              </InputAdornment>
            )
          }}
        />
        <Button
          sx={{my: 2}}
          variant="contained"
          color="primary"
          data-test-id="search-submit"
          onClick={handleSearch}
        >
          {t('LandingPage.Search.Submit')}
        </Button>
      </Grid>
      <Grid item xs={12}>
        {error && (
          <Grid item xs={12} data-test-id="error" pt={2}>
            <Alert severity="error">
              <Typography variant="caption">{error}</Typography>
            </Alert>
          </Grid>
        )}
      </Grid>
    </>
  )
}
