import {AxiosInstance} from 'axios'
import {QueryFunction, useQuery} from 'react-query'
import {IRoster} from '../response/Roster'
import {useApi} from '../providers/OnTimeProvider'
import {DispatchGroups} from '../response/DispatchGroup'
import {SearchTypeAction} from '../common/declarations'

const apiRosterInfoFetcher =
  (secureApi: AxiosInstance): QueryFunction<IRoster, [string, SearchTypeAction]> =>
  async ({queryKey}) => {
    const [value, Type] = queryKey
    const response = await secureApi.get<IRoster>(`/ontime/${Type}/${value}/rosters`)
    return response.data
  }

const apiDispatchGroupsFetcher =
  (secureApi: AxiosInstance): QueryFunction<DispatchGroups, [string]> =>
  async ({queryKey}) => {
    const [countryCode] = queryKey
    const response = await secureApi.get<DispatchGroups>(
      `/ontime/countries/${countryCode}/dispatch-groups`
    )
    return response.data
  }

export const useRoster = (value: string, Type: SearchTypeAction, submitClicked: boolean) => {
  const apiClient = useApi()

  return useQuery([value, Type], apiRosterInfoFetcher(apiClient), {
    retry: false,
    enabled: !!submitClicked
  })
}

export const useDispatchGroups = (countryCode: string) => {
  const apiClient = useApi()

  return useQuery([countryCode], apiDispatchGroupsFetcher(apiClient), {
    retry: false
  })
}
