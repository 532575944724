import React from 'react'
import {DispatchSearch} from './dispatchGroups/DispatchSearch'
import {RosterSearch} from './rosters/RosterSearch'
import {SearchProps, SearchTypeAction} from '../common/declarations'
import {useTranslation} from 'react-i18next'
import {Typography} from '@mui/material'

export const SearchBox: React.FC<SearchProps> = (props: SearchProps) => {
  const {t} = useTranslation()

  return (
    <>
      {props.searchType == SearchTypeAction.DispatchSearch ? (
        <>
          <DispatchSearch
            searchSubmit={props.searchSubmit}
            setSearchValue={props.setSearchValue}
            setHeadline={props.setHeadline}
          />
          <Typography variant="h6">{t('LandingPage.DispatchInformation')}</Typography>
        </>
      ) : (
        <RosterSearch
          searchType={props.searchType}
          searchValue={props.searchValue}
          setSearchValue={props.setSearchValue}
          searchSubmit={props.searchSubmit}
        ></RosterSearch>
      )}
    </>
  )
}
