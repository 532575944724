import {Box, Container} from '@mui/material'
import {useTranslation} from 'react-i18next'

export const PrivacyPage = () => {
  const {t} = useTranslation()

  return (
    <Container data-test-id="privacy-page">
      <Box
        dangerouslySetInnerHTML={{__html: t('PrivacyNotice')}}
        data-test-id="privacyNotice-text"
      />
    </Container>
  )
}
