import React from 'react'
import {Alert, Typography} from '@mui/material'
import {useTranslation} from 'react-i18next'

export const HeadlineInfo = (props: {headline: string | null}) => {
  const {t} = useTranslation()
  return (
    <>
      <Typography variant="h3" data-test-id="headline-info" pb={2}>
        {props.headline}
      </Typography>

      <Alert severity="info" sx={{width: '100%'}} data-test-id="landing-page-disclaimer">
        <Typography variant="caption">{t('Common.Disclaimer')}</Typography>
      </Alert>
    </>
  )
}
