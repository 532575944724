import {Grid, Typography} from '@mui/material'

export const OpenSourceLicense = (props: OssLicense) => {
  const styles = {
    container: {
      padding: 30
    },
    bold: {
      fontWeight: 'bold'
    }
  }

  return (
    <div key={props.moduleName} style={styles.container}>
      <Grid container spacing={2}>
        <Grid item lg={3} sm={6} xs={12}>
          <Typography sx={styles.bold}>{props.name}</Typography>
          <div>{props.version}</div>
          <div>{props.spdx}</div>
          <div>
            <a href={props.repository} target="_blank" rel="noreferrer">
              {props.repository}
            </a>
          </div>
        </Grid>
        <Grid item lg={9} sm={6} xs={12}>
          <Typography align="justify">{props.licenseText}</Typography>
          <div>{props.copyright}</div>
        </Grid>
      </Grid>
    </div>
  )
}

export interface OssLicense {
  moduleName: string
  name: string | null
  version: string | null
  spdx: string
  licenseText: string
  copyright: string
  repository: string
}
